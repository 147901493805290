import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  Home,
  PageAbonosBucaramanga,
  PageAliados,
  PageBackToTheMT,
  PageBoleteriaFull80Manizales,
  PageBucaramangaCarabobo, PageCarreraCorazon,
  PageFcfc,
  PageFcfc2,
  PageFinalLigaBetplay2024,
  PagePanacaViajero, PagePoliticasDeUso,
  PageTerminosyCondiciones
} from '../pages';
import PagePartidoBucaramanga from '../pages/PagePartidoBucaramanga';
import PageSantanderPartido from '../pages/PageSantanderPartido';
import PageSantanderPartido2 from '../pages/PageSantanderPartido2';
import PagePrisioneros from '../pages/PagePrisioneros';
import PageAndinaFest from '../pages/PageAndinaFest';
import PageCantinaDecembrina from '../pages/PageCantinaDecembrina';
import PageRemateCabalgata from '../pages/PageRemateCabalgata';
import PageDespechonato from '../pages/PageDespechonato';
import PageUnlockTheGame from '../pages/PageUnlockTheGame';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='panaca-viajero' element={<PagePanacaViajero />} />
        <Route path='boleteria-full-80-manizales' element={<PageBoleteriaFull80Manizales />} />
        <Route path='aliados-2' element={<PageAliados />} />
        <Route path='politicas-de-privacidad' element={<PagePoliticasDeUso />} />
        <Route path='terminos-y-condiciones' element={<PageTerminosyCondiciones />} />
        <Route path='final-liga-betplay-2024-1-bucaramanga-vs-santafe' element={<PageFinalLigaBetplay2024 />} />
        <Route path='futbol-salon' element={<PageFcfc />} />
        <Route path='futbol-salon-2' element={<PageFcfc2 />} />
        <Route path='abonos-bucaramanga-2024-2' element={<PageAbonosBucaramanga />} />
        <Route path='bucaramanga-carabobo' element={<PageBucaramangaCarabobo />} />
        <Route path='carrera-corazon' element={<PageCarreraCorazon />} />
        <Route path='back-to-the-mt' element={<PageBackToTheMT />} />
        <Route path='bucaramanga-partido' element={<PagePartidoBucaramanga />} />
        <Route path='santander-partido' element={<PageSantanderPartido />} />
        <Route path='santander-partido-2' element={<PageSantanderPartido2 />} />
        <Route path='prisioneros' element={<PagePrisioneros />} />
        <Route path='andina-fest' element={<PageAndinaFest />} />
        <Route path='cantina-decembrina' element={<PageCantinaDecembrina />} />
        <Route path='remate-cabalgata' element={<PageRemateCabalgata />} />
        <Route path='despechonato' element={<PageDespechonato />} />
        <Route path='unlock-the-game' element={<PageUnlockTheGame />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
